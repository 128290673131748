/**
 * Get value from HTML meta tag
 */
function getMetaValue(key: string): string {
  let value = "";
  const node = document.querySelector(`meta[name=${key}]`);
  if (node !== null) {
    value = node.getAttribute("content") || "";
  }
  return value;
}

/**
 * Check is the value is still a placeholder from HTML meta tag
 * @param value value to test
 * @returns true if value starts and ends with "##"
 */
function isPlaceHolder(value: string): boolean {
  return /^##.*##$/.test(value);
}

/**
 * Get a value from the environment or from HTML meta tag.
 * @param key name of the variable to load
 * @returns value from env or from meta tag. Default empty string
 */
function getConfigValue(key: string, defaultValue?: string): string {
  let value: string;
  if (process.env && process.env[`${key}`] !== undefined) {
    value = process.env[`${key}`];
  } else {
    value = getMetaValue(key);
  }
  console.log(value);
  return isPlaceHolder(value) || !value ? defaultValue || "" : value;
}

export default { getConfigValue };
