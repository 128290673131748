import { de } from "vuetify/src/locale";

export default {
  ...de,
  desc: {
    ims: "Der Ort, an dem IMS verwaltet wird",
    keycloak: "Der Identitätsbroker",
    registration: "Erstellen Sie Ihr Login-Master-Konto",
    profile: "Verwalten Sie Ihr Profil",
    mails: "Sehen Sie sich die von IMS gesendeten E-Mails an",
    portainer: "Verwalten Sie die auf diesem Server laufenden Containers",
    rmq: "Siehen Sie Ereignisse, die unter der Haube auftreten",
    prometheus: "Hier beginnt die Überwachung",
    grafana: "Schauen Sie sich an, wie gut das System läuft",
    "securole-demo":
      "Entdecken Sie, wie eine Anwendung dank SecuRole® geschützt werden kann"
  }
};
