import loader from "@/configLoader";
import de from "@/i18n/vuetify/de";
import en from "@/i18n/vuetify/en";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: loader.getConfigValue("VUE_APP_PRIMARY_COLOR", "#189B30"),
        secondary: loader.getConfigValue("VUE_APP_SECONDARY_COLOR", "#F7F7F7"),
        accent: loader.getConfigValue("VUE_APP_ACCENT_COLOR", "#4d4d4d")
      }
    }
  },
  lang: {
    locales: { de, en },
    current: "en"
  }
});
