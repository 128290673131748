























import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {
  name = "App";

  created(): void {
    this.$vuetify.lang.current = navigator.language.slice(0, 2) || "de";
  }
}
