import { en } from "vuetify/src/locale";

export default {
  ...en,

  desc: {
    ims: "The place to administrate IMS",
    keycloak: "The identity broker",
    registration: "Create your Login-Master account",
    profile: "Manage your profile",
    mails: "Have a look at the emails sent by IMS",
    portainer: "Manage the containers running on this server",
    rmq: "See events occurring under the hood",
    prometheus: "Here begins the monitoring",
    grafana: "Have a look on how good the system is running",
    "securole-demo":
      "Discover how an application can be protected thanks to SecuRole®"
  }
};
